import { FC } from "react"
import styled from "@emotion/styled"
import { useTrustpilot } from "../Trustpilot/hooks"

import {
  GridRow,
  breakpoints,
  colours,
  column,
  typographyStyles,
  vertical,
  TrustpilotLogo,
} from "design-kit"

import { ReviewCards } from "./ReviewCards"

const Background = styled.section`
  background-color: ${colours.offBlack};
  padding-top: ${vertical.l};
  padding-bottom: ${vertical.l};
`

const TitleContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 4,
  })}
`

const TrustPilotLogoWrapper = styled.div`
  margin-bottom: ${vertical.m};

  ${breakpoints.tablet`
    margin-bottom: ${vertical.l};
  `}

  > svg {
    max-width: 70%;
  }
`

const Title = styled.h2`
  ${typographyStyles.headline1};
  color: ${colours.white};
  margin-bottom: ${vertical.xs};
`

const Subtitle = styled.p`
  ${typographyStyles.headline};
  color: ${colours.white};
  margin-bottom: ${vertical.xs};
`

const ReviewsContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 8,
  })}
`

const DesktopLineBreak = styled.br`
  display: none;

  ${breakpoints.desktop`
    display: block;
  `}
`

export const Reviews: FC = () => {
  const { stars, trustScore, numberOfReviewsFormatted } = useTrustpilot()
  return (
    <Background>
      <GridRow>
        <TitleContainer>
          <TrustPilotLogoWrapper>
            <TrustpilotLogo tint={colours.white} stars={stars} />
          </TrustPilotLogoWrapper>
          <Title>
            Rated {trustScore}
            <DesktopLineBreak /> out of 5
          </Title>
          <Subtitle>by {numberOfReviewsFormatted}+ people</Subtitle>
        </TitleContainer>

        <ReviewsContainer>
          <ReviewCards />
        </ReviewsContainer>
      </GridRow>
    </Background>
  )
}
