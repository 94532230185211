import React, { FC } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  ArrowRightIcon,
  breakpoints,
  column,
  ExpandableCard,
  GridRow,
  Statement2,
  TertiaryLink,
  useMediaQuery,
  vertical,
} from "design-kit"

import houseGarageDesktopPng from "../../../assets/images/misc/house-garage-desktop.png"
import houseGarageDesktopWebp from "../../../assets/images/misc/house-garage-desktop.webp"
import houseGarageMobilePng from "../../../assets/images/misc/house-garage-mobile.png"
import houseGarageMobileWebp from "../../../assets/images/misc/house-garage-mobile.webp"
import houseGarageTabletPng from "../../../assets/images/misc/house-garage-tablet.png"
import houseGarageTabletWebp from "../../../assets/images/misc/house-garage-tablet.webp"
import houseSimplePng from "../../../assets/images/misc/house-simple.png"
import houseSimpleWebp from "../../../assets/images/misc/house-simple.webp"
import { faqs } from "./Faqs"

const Background = styled.section`
  background-color: #fff691;
  background-image: linear-gradient(#fface5 0%, #fff691 100%);
  padding-top: ${vertical.xl};
  position: relative;

  ${breakpoints.desktop`
    padding-top: ${vertical.xxl};
  `}
`

const FAQContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 8,
    offsetDesktop: 4,
  })}

  margin-top: ${vertical.m};

  ${breakpoints.tablet`
    margin-top: ${vertical.xl};
  `}

  ${breakpoints.desktop`
    position: relative;
  `}
`
export const FAQs: FC = () => {
  const isDesktop = useMediaQuery(breakpoints.desktop.query)
  const isTablet = useMediaQuery(breakpoints.tablet.query) && !isDesktop
  const isMobile = !isTablet && !isDesktop

  const [expandedId, setExpandedId] = React.useState<string | null>(null)

  return (
    <Background>
      <GridRow>
        {isMobile && (
          <picture
            css={css`
              bottom: -66px;
              position: absolute;
              right: 0;
            `}
          >
            <source src={houseGarageMobileWebp} type="image/webp" />
            <img alt="" loading="lazy" src={houseGarageMobilePng} />
          </picture>
        )}

        {isTablet && (
          <picture
            css={css`
              bottom: -40px;
              position: absolute;
              right: 0;
            `}
          >
            <source src={houseGarageTabletWebp} type="image/webp" />
            <img alt="" loading="lazy" src={houseGarageTabletPng} />
          </picture>
        )}

        {isDesktop && (
          <div
            css={css`
              ${column({
                widthDesktop: 12,
              })}
            `}
          >
            <picture
              css={css`
                bottom: 0;
                left: 10px;
                position: absolute;
              `}
            >
              <source src={houseGarageDesktopWebp} type="image/webp" />

              <img alt="" loading="lazy" src={houseGarageDesktopPng} />
            </picture>
          </div>
        )}

        <Statement2>Frequently asked questions</Statement2>
        <FAQContainer>
          {isDesktop && (
            <picture
              css={css`
                bottom: -28px;
                position: absolute;
                right: -22px;
              `}
            >
              <source src={houseSimpleWebp} type="image/webp" />
              <img alt="" loading="lazy" src={houseSimplePng} />
            </picture>
          )}

          <ExpandableCard
            items={faqs.map(faq => {
              const id = "home-buying_faqs_" + faq.id
              return {
                ...faq,
                content: <div>{faq.content}</div>,
                id,
                isExpanded: id === expandedId,
              }
            })}
            onChange={id => setExpandedId(id === expandedId ? null : id)}
          />
          <TertiaryLink
            text="Read all our FAQs"
            href="https://help.habito.com/en/collections/2455970-habito-plus-our-home-buying-service"
            target="_blank"
            icon={{ kind: "right", icon: <ArrowRightIcon /> }}
            width="content-width"
            css={css`
              margin-top: ${vertical.m};
              margin-bottom: ${vertical.xl};

              ${breakpoints.desktop`
                margin-bottom: ${vertical.xxl};
                margin-top: ${vertical.m};
              `}
            `}
          />
        </FAQContainer>
      </GridRow>
    </Background>
  )
}
