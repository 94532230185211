export interface Review {
  quote: string
  reviewer: string
  date: string
}

export const reviews: Review[] = [
  {
    quote:
      "Really easy to communicate. Incredibly helpful and explained some fairly complicated options in easy to digest bites. Quick to respond and friendly. Plus a great rate. Special mention for Christine and Rosie.",
    reviewer: "Matthew",
    date: "13th December 2024",
  },

  {
    quote:
      "Second time using Habito and very happy with the service. It’s really easy to use, upload photos and verify your identity. Thanks Teddy.",
    reviewer: "Carol and Jerry",
    date: "12th December 2024",
  },

  {
    quote:
      "Chisom and Chris were both very knowledgeable and helpful during the entire mortgage process and helped us secure a mortgage offer with no other broker costs.",
    reviewer: "Thanos",
    date: "10th December 2024",
  },

  {
    quote:
      "Extremely easy process, quick responses, easy to use website and ease of uploading documents, ID etc. Takes out a little of that added stress of the whole house buying process!",
    reviewer: "Daniel",
    date: "9th December 2024",
  },

  {
    quote:
      "Perfect service, really fast communication. Hassle-free documents uploads and guidance. Clear step by step guides.",
    reviewer: "Alexander",
    date: "25th November 2024",
  },
]
