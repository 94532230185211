import React from "react"
import styled from "@emotion/styled"

import {
  Body,
  BodyBold,
  TextLink,
  typographyStyles,
  vertical,
} from "design-kit"

const FAQText = styled(Body)`
  :not(:first-of-type) {
    margin-top: ${vertical.xs};
  }
`
const FAQTextBold = styled(BodyBold)`
  :not(:first-of-type) {
    margin-top: ${vertical.xs};
  }
`
const Ul = styled.ul`
  ${typographyStyles.body}
  list-style: disc inside;
  margin-top: ${vertical.xs};
`

export const faqs = [
  {
    id: "what_is_and_isnt_included",
    title: "What is and isn’t included?",
    content: (
      <React.Fragment>
        <FAQText>Here’s what’s included in the fee:</FAQText>
        <Ul>
          <li>Legal costs (like the land registry fee and searches)</li>
          <li>A property survey</li>
          <li>Our support, every step of the way</li>
        </Ul>

        <FAQText>Here’s what can’t be included:</FAQText>
        <Ul>
          <li>Stamp duty</li>
          <li>
            Third party costs (things like indemnity insurance if the seller
            didn’t get planning permission - but don’t worry, your conveyancer
            will always run these by you, there’ll be no surprises!)
          </li>
        </Ul>

        <FAQText>
          Find out more about this stuff{" "}
          <TextLink
            text="here"
            href="https://help.habito.com/en/articles/4248711-what-is-and-isn-t-included-in-plus"
            target="_blank"
            rel="noopener noreferrer"
          />
          .
        </FAQText>
      </React.Fragment>
    ),
  },
  {
    id: "what_happens_if_my_purchase_falls_through",
    title: "What happens if my purchase falls through?",
    content: (
      <React.Fragment>
        <FAQText>
          We know that things don’t always go to plan, so we’ve got you covered.
        </FAQText>

        <FAQText>
          If your purchase falls through before you’ve paid the initial £999
          fee, you don’t have to pay a penny. If it falls through after you’ve
          paid, you won’t have to pay the rest of the fee. We’ll reuse some, or
          all, of the initial fee for the next property you find.
        </FAQText>

        <FAQText>
          Surveys can’t be refunded, and you’d need to get another survey for
          the next property if the first falls through. Don’t worry though, this
          is unlikely.
        </FAQText>

        <FAQText>
          There’s more detail on all of this{" "}
          <TextLink
            text="here"
            href="https://help.habito.com/en/articles/4270685-what-happens-if-my-purchase-falls-through"
            target="_blank"
            rel="noopener noreferrer"
          />
          .
        </FAQText>
      </React.Fragment>
    ),
  },
  {
    id: "how_does_the_fee_work",
    title: "How does the fee work?",
    content: (
      <React.Fragment>
        <FAQTextBold>Get a transparent quote</FAQTextBold>
        <Body>You’ll get a personal quote with a breakdown of everything.</Body>

        <FAQTextBold>Pay in two parts</FAQTextBold>
        <Body>Pay an initial fee of £999, and the rest when you complete.</Body>

        <FAQTextBold>If things go wrong, we’ve got you</FAQTextBold>
        <Body>
          If your purchase falls through for any reason, you can use part of
          your initial fee for the next property.
        </Body>

        <FAQText>
          <TextLink
            text="Here’s more about fees."
            href="https://help.habito.com/en/articles/4248748-how-the-plus-fee-works"
            target="_blank"
            rel="noopener noreferrer"
          />
        </FAQText>
      </React.Fragment>
    ),
  },
  {
    id: "solicitor_conveyancer_whats_the_difference",
    title: "Solicitor, conveyancer: what's the difference?",
    content: (
      <React.Fragment>
        <FAQText>
          Solicitors can work across different areas of the law (they might also
          do something like family law) and conveyancers are trained in
          conveyancing only.
        </FAQText>

        <FAQText>
          Their knowledge of property sales and purchases is exactly the same.
          Using a solicitor or conveyancer makes no difference!
        </FAQText>

        <FAQText>
          <TextLink
            text="Here’s"
            href="https://help.habito.com/en/articles/4270086-solicitor-conveyancer-what-s-the-difference"
            target="_blank"
            rel="noopener noreferrer"
          />{" "}
          a little more detail on this.
        </FAQText>
      </React.Fragment>
    ),
  },
]
