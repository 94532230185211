import React, { FC } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  breakpoints,
  column,
  GridRow,
  horizontal,
  mixins,
  useHasMounted,
  useMediaQuery,
  vertical,
} from "design-kit"

import trackYourPurchaseCard from "../assets/track-your-purchase-card.png"
import {
  Card1,
  Card2,
  Card3,
  Card4,
  cloud,
  Key,
  Pietro,
  SectionHeading,
  SectionSubtitle,
  TrackYourPurchaseCard,
} from "./Components"

const Container = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}
  margin-top: ${vertical.xl};
  padding-bottom: ${vertical.xl};
  position: relative;

  ${breakpoints.tablet`
    width: 90%;
    margin-top: ${vertical.xxl};
    padding-bottom: ${vertical.xxl};
  `}

  ${breakpoints.desktop`
    width: 100%;
  `}
`

const CardsContainer = styled.figure`
  display: flex;
  flex-direction: column;
  margin-top: ${vertical.m};

  ${breakpoints.tablet`
    flex-direction: row;
    align-items: flex-start;
  `}

  ${breakpoints.desktop`
    display: block;
    margin-top: ${vertical.s};
  `}
`

const CardsInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  > * {
    margin-top: ${vertical.s};
  }

  ${breakpoints.tablet`
    margin-left: ${horizontal.m};
    > :first-of-type {
      margin-top: unset;
    }
    > :not(:first-of-type) {
      margin-top: ${vertical.xs};
    }
  `}

  ${breakpoints.desktop`
    flex-direction: row;
    margin-left: unset;
    position: relative;
    > :not(:first-of-type) {
      margin-top: unset;
    }

    > :not(:last-of-type) {
      margin-right: ${horizontal.m};
    }
  `}
`

const Card1Container = styled.div`
  position: relative;
  width: 100%;
`

const Card2Container = styled.div`
  position: relative;
`

const Card4Container = styled.div`
  width: unset;
  position: relative;
  margin-left: 50%;
  margin-right: -28px;

  ${breakpoints.tablet`
    margin-right: unset;
    margin-left: unset;
  `}

  ${breakpoints.desktop`
    width: 100%;
    align-self: flex-end;
    margin-right: -80px;
  `}
`

export const DashboardDisplay: FC = () => {
  const isNonMobile = useMediaQuery(breakpoints.tablet.query)
  const isDesktop = useMediaQuery(breakpoints.desktop.query)
  const hasNoAnimationPreference = useMediaQuery(
    "(prefers-reduced-motion: no-preference)"
  )
  const hasMounted = useHasMounted()

  const [triggerAnimation, setTriggerAnimation] = React.useState<boolean>(false)
  const triggerDivId = "head-in-the-clouds"

  /**
   * this sets the state we listen for
   * to animate the cards
   */
  React.useEffect(() => {
    /** `IntersectionObserver` is not supported by IE11, however
     * it is only used for non-essential animations here.
     * We check if `IntersectionObserver` is present; if not, we
     * proceed without animations.
     */
    if (typeof IntersectionObserver !== "undefined") {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(e => {
          if (
            e.intersectionRatio > 0 &&
            !triggerAnimation &&
            hasNoAnimationPreference
          ) {
            setTriggerAnimation(true)
          }
        })
      })

      const cardsContainer = document.querySelector(`#${triggerDivId}`)
      if (cardsContainer) observer.observe(cardsContainer)
    }
  }, [hasMounted, hasNoAnimationPreference])

  return (
    <GridRow>
      <Container>
        <SectionHeading animate={triggerAnimation} />
        {hasMounted && (
          <React.Fragment>
            <CardsContainer>
              {!isDesktop && (
                <TrackYourPurchaseCard
                  src={trackYourPurchaseCard}
                  animate={triggerAnimation}
                />
              )}
              <CardsInnerContainer>
                {isNonMobile && cloud}
                <Card1Container>
                  <Card1 animate={triggerAnimation} />
                  <Pietro animate={triggerAnimation} />
                  <div id={triggerDivId} />
                </Card1Container>
                {isDesktop ? (
                  <Card2Container>
                    <TrackYourPurchaseCard
                      src={trackYourPurchaseCard}
                      animate={triggerAnimation}
                    />
                    <Card2 animate={triggerAnimation} />
                  </Card2Container>
                ) : (
                  <Card2 animate={triggerAnimation} />
                )}
                {isNonMobile && <Card3 animate={triggerAnimation} />}
                <Card4Container>
                  <Key animate={triggerAnimation} />
                  <Card4 animate={triggerAnimation} />
                </Card4Container>
              </CardsInnerContainer>
              <figcaption
                css={css`
                  ${mixins.invisible}
                `}
              >
                A demo of your home-buying dashboard with clear steps and
                progress indication. A mortgage expert is there to help you
                throughout the process.
              </figcaption>
            </CardsContainer>
            <SectionSubtitle animate={triggerAnimation} />
          </React.Fragment>
        )}
      </Container>
    </GridRow>
  )
}
