import { FC } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  BodyBold,
  breakpoints,
  Card as DKCard,
  colours,
  Headline2,
  horizontal,
  media,
  Tag,
  TagType,
  typographyStyles,
  vertical,
} from "design-kit"

import cloudPng from "../assets/cloud.png"
import cloudWebp from "../assets/cloud.webp"
import keyGif from "../assets/key-hovering.gif"
import keyWebP from "../assets/key-hovering.webp"
import keyPng from "../assets/key-hovering-fallback.png"
import keyPng2x from "../assets/key-hovering-fallback-retina.png"
import pietroPng from "../assets/pietro.png"
import pietroWebP from "../assets/pietro.webp"

import { transitions } from "./animationHelpers"

export const SectionHeading: FC<{ animate: boolean }> = ({ animate }) => (
  <Headline2
    css={css`
      max-width: 70%;

      ${media.allowsMotion`
        opacity: 0;
        position: relative;
        left: -100px;
        transition: ${transitions.sectionHeading};

        ${
          animate
            ? `
              opacity: 1;
              transform: translateX(100px);
            `
            : ""
        }
      `}
    `}
  >
    An expert team sticks by your{" "}
    <span
      css={css`
        display: block;
      `}
    >
      side the whole way
    </span>
  </Headline2>
)

export const SectionSubtitle: FC<{ animate: boolean }> = ({ animate }) => (
  <p
    css={css`
      ${typographyStyles.statement4}
      margin-top: ${vertical.m};

      ${media.allowsMotion`
        opacity: 0;
        position: relative;
        left: -100px;
        transition: ${transitions.sectionSubtitle};

        ${
          animate
            ? `
            opacity: 1;
            transform: translateX(100px);
          `
            : ""
        }

        ${breakpoints.desktop`
          left: 100px;

          ${
            animate
              ? `
              transform: translateX(-100px);
            `
              : ""
          }
        `}
      `}

      ${breakpoints.tablet`
        position: absolute;
        bottom: 220px;
      `}

      ${breakpoints.desktop`
        position: relative;
        bottom: unset;
        text-align: right;
      `}
    `}
  >
    Track everything in one{" "}
    <span
      css={css`
        display: block;
      `}
    >
      place, on your shiny
    </span>
    <span
      css={css`
        display: block;
      `}
    >
      {" "}
      Habito dashboard.
    </span>
  </p>
)

export const Pietro: FC<{ animate: boolean }> = ({ animate }) => (
  <picture>
    <source srcSet={pietroWebP} type="image/webp" />
    <img
      src={pietroPng}
      alt=""
      css={css`
        width: 160px;
        position: absolute;
        top: -330px;
        right: -10px;
        user-select: none;

        ${media.allowsMotion`
          opacity: 0;
          transform: scale(0.7);
          transition: ${transitions.pietro};

          ${
            animate
              ? `
              opacity: 1;
              transform: scale(1);
            `
              : ""
          }
        `}

        ${breakpoints.tablet`
          top: -110px;
          right: -60px;
        `}

        ${breakpoints.desktop`
          width: 200px;
          top: 80px;
          right: -50px;
        `}
      `}
    />
  </picture>
)

export const TrackYourPurchaseCard: FC<{ src: string; animate: boolean }> = ({
  src,
  animate,
}) => {
  return (
    <img
      src={src}
      alt=""
      css={css`
        max-width: 288px;
        user-select: none;
        position: relative;

        ${media.allowsMotion`
          opacity: 0;
          left: -100px;
          transition: ${transitions.dashboardCard};

          ${
            animate
              ? `
                opacity: 1;
                transform: translateX(100px);
              `
              : ""
          }
          
          ${breakpoints.desktop`
            left: unset;
            top: -100px;

            ${
              animate
                ? `
                transform: translateY(100px);
              `
                : ""
            }
          `}
        `}
      `}
    />
  )
}

const CardBase = styled(DKCard)`
  border: none;
  box-shadow: 0 0 8px 0 ${colours.greyScale.grey75};
  padding: ${vertical.s} ${horizontal.s};
  height: max-content;

  ${breakpoints.tablet`
    padding: ${vertical.s} ${horizontal.s};
  `}

  ${breakpoints.desktop`
    padding: ${vertical.s} ${horizontal.s};
  `}
`

interface Card {
  text: string
  tagText: string
  tagType: TagType
  className?: string
}
const Card: FC<Card> = ({ text, tagText, tagType, className }) => (
  <CardBase element="div" className={className}>
    <Tag text={tagText} kind={tagType} />
    <BodyBold
      css={css`
        margin-top: ${vertical.xs};
      `}
    >
      {text}
    </BodyBold>
  </CardBase>
)

export const Card1: FC<{ animate: boolean }> = ({ animate }) => (
  <Card
    text="Mortgage application sent"
    tagText="Done"
    tagType="positive"
    css={css`
      width: unset;
      margin-right: -28px;
      position: relative;

      ${media.allowsMotion`
        opacity: 0;
        left: 100px;
        transition: ${transitions.card1};

        ${
          animate
            ? `
              opacity: 1;
              transform: translateX(-100px);
            `
            : ""
        }

        ${breakpoints.desktop`
          left: -100px;

          ${
            animate
              ? `
              transform: translateX(100px);
            `
              : ""
          }
        `}
      `}

      ${breakpoints.tablet`
        margin-left: 60px;
        margin-right: -60px;
      `}

      ${breakpoints.desktop`
        margin-left: unset;
        margin-right: unset;
      `}
    `}
  />
)

export const Card2: FC<{ animate: boolean }> = ({ animate }) => (
  <Card
    text="Your homebuying survey"
    tagText="In progress"
    tagType="progress"
    css={css`
      margin-left: -26px;
      position: relative;

      ${media.allowsMotion`
        opacity: 0;
        left: -100px;
        transition: ${transitions.card2};

        ${
          animate
            ? `
              opacity: 1;
              transform: translateX(100px);
            `
            : ""
        }

        ${breakpoints.tablet`
          left: 100px;
          
          ${
            animate
              ? `
              transform: translateX(-100px);
            `
              : ""
          }
        `}
      `}

      ${breakpoints.tablet`
        margin-left: unset;
      `}

      ${breakpoints.desktop`
        position: absolute;
        bottom: -136px;
        width: 120%;
        height: unset; // for Safari
      `}
    `}
  />
)

export const Card3: FC<{ animate: boolean }> = ({ animate }) => (
  <Card
    text="Your legal work"
    tagText="Coming up"
    tagType="neutral"
    css={css`
      position: relative;

      ${media.allowsMotion`
        ${breakpoints.tablet`
          opacity: 0;
          left: 100px;
          transition: ${transitions.card3};

          ${
            animate
              ? `
              opacity: 1;
              transform: translateX(-100px);
            `
              : ""
          }
        `}
      `}

      ${breakpoints.tablet`
        margin-left: 60px;
        margin-right: -60px;
      `}

      ${breakpoints.desktop`
        align-self: flex-end;
        margin-left: unset;
        margin-right: unset;
      `}
    `}
  />
)

export const Card4: FC<{ animate: boolean }> = ({ animate }) => (
  <Card
    text="Get the keys!"
    tagText="Coming up"
    tagType="neutral"
    css={css`
      position: relative;

      ${media.allowsMotion`
        opacity: 0;
        left: 100px;
        transition: ${transitions.card4AndKeyMobile};

        ${
          animate
            ? `
              opacity: 1;
              transform: translateX(-100px);
            `
            : ""
        }

        ${breakpoints.tablet`
          transition: ${transitions.card4AndKey};
        `}
      `}
    `}
  />
)

export const cloud = (
  <picture>
    <source srcSet={cloudWebp} type="image/webp" />
    <img
      src={cloudPng}
      alt=""
      css={css`
        display: none;
        user-select: none;

        ${breakpoints.tablet`
          display: block;
          position: absolute;
          bottom: 90px;
          left: -200px;
        `}

        ${breakpoints.desktop`
          bottom: -200px;
          left: -330px;
        `}
      `}
    />
  </picture>
)

export const Key: FC<{ animate: boolean }> = ({ animate }) => (
  <picture>
    <source
      srcSet={keyWebP}
      media={media.allowsMotion.query}
      type="image/webp"
    />
    <source srcSet={keyGif} media={media.allowsMotion.query} type="image/gif" />
    <source
      srcSet={keyPng2x}
      media={media.retinaScreen.query}
      type="image/png"
    />
    <img
      src={keyPng}
      alt=""
      css={css`
        position: absolute;
        width: 260px;
        transform: rotate(13deg);
        z-index: 2;
        left: -230px;
        top: -40px;
        user-select: none;

        ${media.allowsMotion`
          opacity: 0;
          left: -330px;
          transition: ${transitions.card4AndKeyMobile};

          ${
            animate
              ? `
              opacity: 1;
              transform: rotate(13deg) translateX(100px);
            `
              : ""
          }
        `}

        ${breakpoints.tablet`
          left: unset;
          right: -60px;
          top: -10px;

          ${media.allowsMotion`
            opacity: 0;
            right: -160px;
            transition: ${transitions.card4AndKey};

            ${
              animate
                ? `
                opacity: 1;
                transform: rotate(13deg) translateX(-100px);
              `
                : ""
            }
          `}
        `}

        ${breakpoints.desktop`
          width: 300px;
          top: -130px;
        `}
      `}
    />
  </picture>
)
